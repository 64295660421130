<template>
  <div v-show="selectedTitle === title" :data-testid="`content-${title}`">
    <slot></slot>
  </div>
</template>
<script setup lang="ts">
const selectedTitle = inject('selectedTitle')

interface TabProps {
  title?: string
  count?: number
  mobile?: string
  disable?: boolean
}

defineProps<TabProps>()
</script>
