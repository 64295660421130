<template>
  <div class="tabs-wrapper" data-testid="tabs">
    <div class="tabs-header">
      <ul>
        <li
          v-for="(tab, index) in tabData"
          :key="tab.title"
          :data-testid="`tab-${index + 1}`"
          :class="[
            tab.title === selectedTitle ? 'selected' : '',
            tab.disable ? 'disabled' : ''
          ]"
          class="flex items-end group"
          :tabindex="tab.disable ? -1 : 0"
          @keydown.enter.prevent="changeTab(tab.title)"
          @keydown.space.prevent="changeTab(tab.title)"
          @click="changeTab(tab.title)"
        >
          <div class="flex items-center">
            <span class="inline md:hidden tab-title-mobile">{{
              tab.mobile || tab.title
            }}</span>
            <span class="hidden md:inline tab-title">{{ tab.title }}</span>
            <span
              v-if="tab.count != null"
              class="badge"
              v-html="getBadgeCount(tab.count)"
            ></span>
          </div>
        </li>
      </ul>
    </div>
    <div class="tabs-content">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
const slots = useSlots()

const emit = defineEmits<{
  (e: 'tabChange', tab: string): void
}>()

const tabData = computed(() =>
  slots.default?.().map((tab) => ({
    title: tab.props?.title,
    mobile: tab.props?.mobile,
    count: tab.props?.count,
    disable: tab.props?.disable
  }))
)

// if number of search results is greater than 99
const getBadgeCount = (count: number) =>
  count > 99
    ? `<span class="inline-flex items-center"><span>99</span><span class="text-[10px]">+</span></span>`
    : count.toString()

const selectedTitle = ref(tabData.value?.[0]?.title)
provide('selectedTitle', selectedTitle)

function changeTab(tabTitle: string) {
  selectedTitle.value = tabTitle
  emit('tabChange', selectedTitle.value)
}

defineExpose({
  changeTab
})
</script>

<style scoped lang="postcss">
.tabs-wrapper {
  .tabs-header {
    ul {
      @apply flex flex-row space-x-2 md:space-x-4 pl-6 md:pl-20;
    }

    .badge {
      @apply bg-brand-blue-700 text-white text-xs font-semibold rounded-full w-7 h-7 flex items-center justify-center ml-2;
    }

    li {
      @apply flex-grow md:flex-grow-0 py-3 px-5 select-none font-bold text-base text-black -mb-px rounded-t-lg border-b-6 border-brand-grey-200 bg-brand-grey-100 cursor-pointer;

      .tab-title {
        @apply text-xl;
      }

      .badge {
        @apply bg-brand-grey-200 text-black;
      }
    }

    li.selected {
      @apply bg-white border-transparent border-b-brand-blue-200;

      .badge {
        @apply bg-brand-blue-700 text-white group-hover:bg-brand-grey-400;
      }
    }

    li.disabled {
      @apply pointer-events-none;
    }
  }

  .tabs-content {
    @apply p-3;
  }
}
</style>
